import React from "react"
import Header from "../components/Header"
import Landing from "../components/Landing"
import FirstTrenner from "../components/FirstTrenner"
import Service from "../components/Service"
import Bio from "../components/Bio"
import SecondTrenner from "../components/SecondTrenner"
import Gallery from "../components/Gallery"
import Preise from "../components/Preise"
import Kontakt from "../components/Kontakt"
import Footer from "../components/Footer"
import Text from "../components/Text"
import { Helmet } from "react-helmet"
import Text2 from "../components/Text2"

function index() {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Podologische Praxis | Sara Reschke | Braunschweig | Am
          Niebelungenplatz
        </title>
        <meta
          name="description"
          content="Die Podologische Praxis am Niebelungenplatz in Braunschweig. Vereinbaren sie noch HEUTE einen Termin!"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="canonical" href="https://podologie-reschke.de" />
      </Helmet>
      <Header />
      <Landing />
      <FirstTrenner />
      <Text />
      <Service />
      <Text2 />
      <Bio />
      <SecondTrenner />
      <Gallery />
      <Preise />
      <Kontakt />
      <Footer />
    </div>
  )
}

export default index
