import React from "react"
import { Container, Row, Col, Image } from "react-bootstrap"
import Foto3 from "../images/praxis1.jpg"
import Foto4 from "../images/praxis3.jpg"

function Gallery() {
  return (
    <div id="gallery">
      <Container
        style={{
          padding: "0px",
        }}
        fluid
      >
        <Row noGutters>
          <Col xl={5}>
            <Image
              src={Foto4}
              className="h-100 w-100"
              alt="Podologische Praxis - Reschke"
            />
          </Col>
          <Col xl={7}>
            <Image
              src={Foto3}
              className="w-100"
              alt="Podologische Praxis - Reschke"
            />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Gallery
