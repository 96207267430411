import React, { Component } from "react"
import { Container, Row, Col } from "react-bootstrap"

export default class Text2 extends Component {
  render() {
    return (
      <Container style={{ marginTop: "200px", marginBottom: "130px" }}>
        <Row>
          <Col>
            <h4>
              <small class="text-muted">
                Die Praxis verfügt über eine Zulassung für alle Krankenkassen,
                somit sind Personen mit diabetischen Füßen, Marcumar - Patienten
                oder vorgeschädigten Blutgefäßen bei mir in besten Händen.
              </small>
            </h4>
          </Col>
        </Row>
      </Container>
    )
  }
}
