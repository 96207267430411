import React, { Component } from "react"
import { Container, Row, Col } from "react-bootstrap"
import style from "../styles/kontakt.module.css"
import { FaLocationArrow } from "react-icons/fa"
import { FaPhone } from "react-icons/fa"
import { FaFax } from "react-icons/fa"
import { FaClock } from "react-icons/fa"
import { IoIosMail } from "react-icons/io"

export default class Kontakt extends Component {
  render() {
    return (
      <div id="kontakt">
        <Container fluid>
          <Row>
            <Col
              xl={6}
              className="d-flex flex-column align-items-center justify-content-center mx-auto"
              style={{
                height: "800px",
              }}
            >
              <div className={style.maps} id="maps">
                <iframe
                  className="embed-responsive-item"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2440.6343481041836!2d10.524571315798754!3d52.28633997977059!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47aff68356037d31%3A0x308a1043991a0e21!2sNibelungenpl.%208%2C%2038106%20Braunschweig!5e0!3m2!1sde!2sde!4v1574266042307!5m2!1sde!2sde"
                  width="350"
                  title="Google Maps"
                  height="600"
                  frameBorder="0"
                  style={{ border: "0px", borderRadius: "10px" }}
                  allowFullScreen=""
                ></iframe>
              </div>
            </Col>
            <Col
              xl={6}
              className="d-flex flex-column align-items-center justify-content-center mx-auto"
              style={{
                height: "800px",
              }}
            >
              <div>
                <ul className="list-unstyled h4">
                  <li>
                    <p className="font-weight-bold">
                      Podologische Praxis Reschke
                    </p>
                  </li>
                  <li>
                    <p className="font-weight-light">
                      <FaLocationArrow
                        style={{ marginRight: "10px", color: "#00869b" }}
                      />
                      Nibelungenplatz 8
                      <br />
                      38106 Braunschweig
                    </p>
                  </li>
                  <li>
                    <p className="font-weight-light">
                      <FaPhone
                        style={{ marginRight: "10px", color: "#00869b" }}
                      />
                      0531/ 23 65 60 80
                    </p>
                  </li>
                  <li>
                    <p className="font-weight-light">
                      <FaFax
                        style={{ marginRight: "10px", color: "#00869b" }}
                      />
                      0531/ 23 65 60 81
                    </p>
                  </li>
                  <li>
                    <p className="font-weight-light">
                      <IoIosMail
                        style={{ marginRight: "10px", color: "#00869b" }}
                      />
                      <a
                        className="text-dark"
                        href="mailto:praxisreschke@gmx.de"
                      >
                        praxisreschke@gmx.de
                      </a>
                    </p>
                  </li>
                  <br />
                  <li>
                    <p className="font-weight-light">
                      <FaClock
                        style={{ marginRight: "10px", color: "#00869b" }}
                      />
                      Mo - Fr. 9.00 - 12.00
                    </p>
                  </li>
                  <li>
                    <p className="font-weight-light">
                      <FaClock
                        style={{ marginRight: "10px", color: "#00869b" }}
                      />
                      Mo - Di - Do. 13.00 - 18.00
                    </p>
                  </li>
                  <li>
                    <p className="font-weight-light">
                      Und Termin nach Vereinbarung
                    </p>
                  </li>
                  <li>
                    <p className="font-weight-light mt-5">
                      <small>
                        Die Praxis Reschke ist barrierefrei. Sie erreichen die
                        Praxis mit dem PKW bzw. mit der Buslinien 416 und 464
                        oder der Tram 2.
                      </small>
                    </p>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}
