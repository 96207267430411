import React from "react"
import { Container, Row, Col, Image } from "react-bootstrap"
import style from "../styles/bio.module.css"
import Frau from "../images/Sara-Reschke.jpg"

function Bio() {
  return (
    <div>
      <Container>
        <Row className={style.padding}>
          <Col className="d-flex justify-content-center" xl={6}>
            <Image
              alt="Sara Reschke"
              className={`${style.biopic} ${style.width} ${style.nonboxs}`}
              src={Frau}
              rounded
            />
          </Col>
          <Col className="text-center pt-5 px-5" xl={6}>
            <h1 className="display-3">Sara Reschke</h1>
            <h2 className="font-weight-light text-primary display-5">
              Inhaberin
            </h2>
            <br />
            <p>
              Meine berufliche Laufbahn begann 2008 in einer podologischen
              Praxis. Als staatlich anerkannte Podologin ist das Ziel meiner
              Tätigkeit die Linderung von Beschwerden sowie die Durchführung
              vorbeugender Maßnahmen wie z.B der allgemeinen podologischen
              Komplexbehandlung. Ein vertrauensvoller Kontakt und eine
              ausführliche Beratung für Ihre individuelle Behandlung sind mir
              ein selbstverständliches Anliegen.
            </p>
            <p>Ich freue mich, Sie bald in meiner Praxis begrüßen zu dürfen.</p>
            <p className="font-italic">Ihre Sara Reschke</p>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Bio
