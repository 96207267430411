import React from "react"
import { Container, Row, Col } from "react-bootstrap"

function FirstTrenner() {
  return (
    <div>
      <Container style={{ backgroundColor: "#00869B", height: "250px" }} fluid>
        <Row className="align-items-center h-100 first-trenner">
          <Col xl="6">
            <h2 className="text-white text-center second-trenner">
              „Gehen ist des Menschen beste Medizin“
            </h2>
            <h4 className="text-white font-weight-light text-center">
              (Hippokrates von Kos.)
            </h4>
          </Col>
          <Col xl="6">
            <p className="text-white display-3 text-center telefon-nummer">
              0531 / 23 65 60 80
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default FirstTrenner
