import React, { Component } from "react"
import { Container, Row, Col } from "react-bootstrap"

export default class Text extends Component {
  render() {
    return (
      <Container style={{ marginTop: "200px", marginBottom: "130px" }}>
        <Col>
          <Row>
            <h3>
              Podologischen Praxis Reschke im Ärztehaus am Nibelungenplatz in
              Braunschweig.
            </h3>
            <br />
            <br />
            <br />
            <h4>
              <small class="text-muted">
                Die Praxis bietet Ihnen neben der podologischen Behandlung
                (medizinische Fußpflege) ein großes Spektrum an weiteren
                Maßnahmen wie zum Beispiel die Behandlung von Problemnägeln, die
                Beseitigung von schmerzenden Hautschwielen bis hin zur Fertigung
                von Nagelkorrekturspangen.
              </small>
            </h4>
          </Row>
        </Col>
      </Container>
    )
  }
}
