import React from "react"
import { Container, Button, Row, Col, Card, Accordion } from "react-bootstrap"

function Service() {
  return (
    <div id="service">
      <Container className="pt-5">
        <Row className="py-5 d-flex justify-content-between">
          <Col>
            <h1 className="text-center display-4 mb-5">Meine Leistungen</h1>
            <Accordion role="button" defaultActiveKey="0">
              <Card>
                <Card.Header className="text-center">
                  <Accordion.Toggle
                    style={{ fontSize: "13px" }}
                    as={Button}
                    variant="link"
                    eventKey="1"
                  >
                    Podologische Behandlungen
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    <p>
                      Das fachgerechtes Schneiden und Bearbeiten der Nägel sowie
                      das Abtragen von Nagelverdickungen, Entfernung von
                      Schwielen und Hornhaut. Diabetiker erhalten für diese
                      Behandlung im Regelfall eine Heilmittelverordnung.
                    </p>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header className="text-center">
                  <Accordion.Toggle
                    style={{ fontSize: "13px" }}
                    as={Button}
                    variant="link"
                    eventKey="2"
                  >
                    Nagelkorrekturspangen bei eingewachsenen Nägeln
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body>
                    Die Spangentherapie ist eine Nageltechnik,die anwendet wird,
                    wenn der Zehennagel eingewachsen oder eingerollt ist. Ziel
                    der Behandlung ist, die seitlichen Nagelränder anzuheben,
                    bis der Nagel seine flache, breite Form erreicht hat.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header className="text-center">
                  <Accordion.Toggle
                    style={{ fontSize: "13px" }}
                    as={Button}
                    variant="link"
                    eventKey="3"
                  >
                    Nagelprothetik
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                  <Card.Body>
                    Zur optischen Verschönerung und zugleich zum Schutz des
                    Nagelbetts nach einer Verletzung. Die Nagelprothetik wird
                    mit Hilfe eines UV-Gels auf den Nagel aufgetragen und
                    modelliert und mit UV-Licht ausgehärtet. Das Ergebnis ist
                    dem natürlichen Fussnagel sehr ähnlich.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header className="text-center">
                  <Accordion.Toggle
                    style={{ fontSize: "13px" }}
                    as={Button}
                    variant="link"
                    eventKey="4"
                  >
                    Nagelpilzbehandlung
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                  <Card.Body>
                    Nagelpilz ist eine ansteckende Infektionskrankheit und
                    beinhaltet zusätzlich zu der podologischen Komplexbehandlung
                    eine gewissenhafte Selbsttherapie und enge Zusammenarbeit
                    mit Ihrem Dermatologen um eine schnelle Heilung zu erzielen.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Service
