import React from "react"
import { Navbar, Nav, Container, Image } from "react-bootstrap"
// import { FaFacebookSquare } from "react-icons/fa"
// import { FaInstagram } from "react-icons/fa"
import Logo from "../images/logo1.jpg"
import "../styles/header.css"

function Header() {
  return (
    <div>
      <Navbar
        collapseOnSelect
        expand="md"
        className="py-4 bg-white"
        fixed="top"
      >
        <Container fluid className="p-0">
          <Navbar.Brand className="text-body" href="#home">
            <Image style={{ height: "70px" }} src={Logo} />
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Nav.Link className="text-body font-weight-bold" href="#home">
              Home
            </Nav.Link>

            <Nav.Link className="text-body font-weight-bold" href="#service">
              Leistung
            </Nav.Link>

            <Nav.Link className="text-body font-weight-bold" href="#gallery">
              Gallerie
            </Nav.Link>

            <Nav.Link className="text-body font-weight-bold" href="#preisliste">
              Preisliste
            </Nav.Link>

            <Nav.Link className="text-body font-weight-bold" href="#kontakt">
              Kontakt
            </Nav.Link>

            {/* <Nav.Link href="#features">
              <FaFacebookSquare />
            </Nav.Link>

            <Nav.Link href="#features">
              <FaInstagram />
            </Nav.Link> */}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  )
}

export default Header
