import React, { Component } from "react"
import { Container, Row, Col, Nav } from "react-bootstrap"
// import { FaFacebookSquare } from "react-icons/fa"
// import { FaInstagram } from "react-icons/fa"
import { Link } from "gatsby"

export default class Footer extends Component {
  render() {
    return (
      <div>
        <Container fluid>
          <Row>
            <Col
              className="d-flex align-items-center justify-content-end"
              style={{ backgroundColor: "#00869B", height: "120px" }}
            >
              <Nav className="">
                <Nav.Item as="li">
                  <Nav.Link>
                    <Link className="text-white" to="/impressum/">
                      Impressum
                    </Link>
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item as="li">
                  <Nav.Link>
                    <Link className="text-white" to="/datenschutz/">
                      Datenschutz
                    </Link>
                  </Nav.Link>
                </Nav.Item>

                {/* <Nav.Item as="li">
                  <Nav.Link className="text-white" href="/home">
                    <FaFacebookSquare />
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link className="text-white" href="/home">
                    <FaInstagram />
                  </Nav.Link>
                </Nav.Item> */}
              </Nav>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}
