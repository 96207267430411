import React from "react"
import { Container, Row, Col } from "react-bootstrap"

function FirstTrenner() {
  return (
    <div>
      <Container
        style={{
          backgroundColor: "#00869B",
          height: "250px",
          marginTop: "150px",
        }}
        fluid
      >
        <Row className="align-items-center h-100">
          <Col xl={12}>
            <h5 className="text-white">
              In meiner Praxis wird nach neusten Hygienestandards gearbeitet.
            </h5>
            <h5 className="text-white font-weight-light">
              Zusätzlich unterliegen wir Podologen einer strengen
              Fortbildungspflicht und diversen Auflagen durch verschiedene
              Kontrollorgane.
            </h5>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default FirstTrenner
